<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>资产管理</BreadcrumbItem>
                <BreadcrumbItem>资产设置</BreadcrumbItem>
                <BreadcrumbItem>标签模板</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="inner-content">
            <Form ref="viewForm" label-colon :label-width="90">
                <FormItem label="标签模板">
                    <div class="template-label" v-for="template in templateList" :key="template.id">
                        <div class="template-label-card">
                            <Card v-if="template.id === 'temp100'">
                                <Row>
                                    <Col><h2>陕西延长石油神榆能源化工有限公司</h2></Col>
                                </Row>
                                <Row>
                                    <Col span="16">
                                        <p style="line-height: 24px;">
                                            名称：惠普D485打印机<br />
                                            型号：HP445<br />
                                            分类：打印机<br />
                                            编码：12451647986
                                        </p>
                                    </Col>
                                    <Col span="8">
                                        <img src="../../../../assets/images/qr_default.png" />
                                    </Col>
                                </Row>
                            </Card>
                            <Card v-if="template.id === 'temp200'">
                                <img src="../../../../assets/images/qr_default.png" />
                                <p>No.12451647986</p>
                            </Card>
                        </div>
                        <RadioGroup v-model="checkd">
                            <Radio :label="template.id">{{template.name}}</Radio>
                        </RadioGroup>
                    </div>
                </FormItem>
                <FormItem label="温馨提示">
                    设置打印机纸张长、宽比为2.5可得到最佳打印效果。如：纸带宽度为18mm，设置长度为45mm最佳；宽度为24mm，设置长度为60最佳。
                </FormItem>
            </Form>
            <div class="footer-btn"><Button type="primary" @click="submit">保存</Button></div>
        </div>
    </div>
</template>

<script>
import permission from '@/common/permission';
import { reqGetTabTemplate, reqChooseTemplate } from '../../../../api/asset-api'

export default {
    name: 'labelList',
    components: {

    },
    data() {
        return {
            permissionButton: {
                add: false,
                edit: false,
                del: false,
                view: false,
            },
            templateList: [],
            loading: false,
            checkd: '',
        }
    },
    methods: {
        //获取标签模板
        doSearching() {
            reqGetTabTemplate().then((res) => {
                this.templateList = res.data.data;
                this.checkd = this.templateList.find(item => item.isEnabled === 1).id;
            }).catch(() => {
                this.templateList = [];
            });
        },
        submit(){
            this.loading = true;
            reqChooseTemplate({templateId: this.checkd}).then((res) => {
                if (res.data.code === 1) {
                    this.loading = false;
                    this.$Message.success(res.data.msg);
                    this.doSearching();
                } else {
                    this.loading = false;
                    this.$Message.warning(res.data.msg);
                }
            }).catch(() => {
                this.loading = false;
                this.$Message.error('保存失败');
            });
        }
    },
    mounted() {
        this.doSearching();
        let permissionId = this.$route.query.permissionId;
        permission.getPermissionButton(permissionId, this.permissionButton);
    }
}
</script>
